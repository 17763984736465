import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import { media } from "styles/media"
import Layout from "components/layout/layout"
import SEO from "components/seo"

const StyledImg = styled(Img)`
  max-width: 100%;
`

const StyledSection = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, fit-content);
  grid-row-gap: 32px;
  margin-bottom: 64px !important;
  margin-top: 90px !important;
  ${media.tablet} {
    margin-top: 60px !important;
  }
  ${media.pc} {
    grid-template-columns: 430px 430px;
    grid-template-rows: repeat(3, minmax(auto, 320px));
    grid-row-gap: 100px;
    grid-column-gap: 60px;
    justify-content: center;
    margin: 60px auto 90px !important;
    grid-template-areas: "content empty"
                        "img1 img2"
                        "img3 img4";
    // prettier-ignore
    ${StyledImg}:nth-child(2) {
      grid-area: img2;
      transform: translateY(-210px);
    }
    // prettier-ignore
    ${StyledImg}:nth-child(3) {
      grid-area: img1;
    }
    // prettier-ignore
    ${StyledImg}:nth-child(4) {
      grid-area: img4;
      transform: translateY(-210px);
    }
    // prettier-ignore
    ${StyledImg}:nth-child(5) {
      grid-area: img3;
    }
  }
  ${media.pcBig}{
    grid-template-columns: 480px 480px;
    grid-template-rows: repeat(3, 320px);
    grid-column-gap: 220px;
  }
`

const Content = styled.div`
  color: ${props => props.theme.color.dark2};
  ${media.pc} {
    align-self: flex-end;
    grid-area: content;
  }
  & h1 {
    font-size: ${props => props.theme.font.xl};
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 3px;
    ${media.tablet} {
      font-size: ${props => props.theme.font.xxl};
      line-height: 76px;
      margin-bottom: 12px;
    }
  }
  & p {
    font-size: ${props => props.theme.font.s};
    line-height: 26px;
    letter-spacing: 0.3px;
    font-weight: 500;
    ${media.tablet} {
      font-size: ${props => props.theme.font.m};
      line-height: 36px;
    }
  }
`

const Gabintet = () => {
  const { img1, img2, img3, img4 } = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "gabinet/1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2: file(relativePath: { eq: "gabinet/2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img3: file(relativePath: { eq: "gabinet/3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img4: file(relativePath: { eq: "gabinet/4.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Gabinet" />
      <StyledSection className="container">
        <Content>
          <h1>Gabinet</h1>
          <p>
            Posiadamy nowoczesny oraz dobrze wyposażony gabinet stomatologiczny, w którym wykonujemy wszystkie zabiegi.
          </p>
        </Content>
        <StyledImg fluid={img1.childImageSharp.fluid} alt="gabinet-1" objectFit="contain" />
        <StyledImg fluid={img2.childImageSharp.fluid} alt="gabinet-2" objectFit="contain" />
        <StyledImg fluid={img3.childImageSharp.fluid} alt="gabinet-3" objectFit="contain" />
        <StyledImg fluid={img4.childImageSharp.fluid} alt="gabinet-4" objectFit="contain" />
      </StyledSection>
    </Layout>
  )
}

export default Gabintet
